/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  width: 240px;
  margin-left: -120px;
}
.section--content {
  background-size: 460px 310px;
  background-position: 50px 60px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
div.main {
  width: 104%;
  margin-left: -2%;
}
div.side {
  width: 104%;
  margin-left: -2%;
}
div.base {
  width: 104%;
  margin-left: -2%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */